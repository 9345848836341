import { AutomationCampaignType, getAutomationCampaignDefaultData } from '@/models/automation-campaign';
import { reactive } from 'vue';
import { request } from '@/utils';
import _ from 'lodash';

// 自动加_destroy
function getSubmitDataWithDestroyFlag<OT extends Record<string, any>, T extends Record<string, any>>(originData: OT, data: T): T {
  return _.mapValues(data, (v, key) => {
    if (_.isArray(v)) {
      const deletedItems = [] as typeof v;
      const firstOriginItem = _.get(originData, `${key}.${0}`);
      // Array<Object>类型的  并且Object有id 没有 signed_id的加_destroy
      if (_.isPlainObject(firstOriginItem) && firstOriginItem.id && !firstOriginItem.signed_id) {
        _.forEach(originData[key], (originItem) => {
          if (!v.find((item: any) => item.id === originItem.id)) {
            deletedItems.push({ ...originItem, _destroy: true });
          }
        });
        const originDataKeyById = _.keyBy(originData[key], 'id');
        return v.map((item: any) => {
          if (originDataKeyById[item.id]) {
            return getSubmitDataWithDestroyFlag(originDataKeyById[item.id], item);
          }
          return item;
        }).concat(deletedItems);
      }
    }
    return v;
  });
}

export class AutomationCampaignStore {
  data = reactive<AutomationCampaignType>(getAutomationCampaignDefaultData());
  originData: AutomationCampaignType = getAutomationCampaignDefaultData();
  merchantId: number;
  campaignId: number;

  constructor(merchantId: number, campaignId: number) {
    this.merchantId = merchantId;
    this.campaignId = campaignId;
  }

  async fetchData() {
    const { data } = await request.get<AutomationCampaignType>(`merchants/${this.merchantId}/automation_campaigns/${this.campaignId}`);
    Object.assign(this.data, _.cloneDeep(data));
    this.originData = _.cloneDeep(data);
    return data;
  }

  async handleSubmit() {
    const body = getSubmitDataWithDestroyFlag(this.originData.google_campaign!, this.data.google_campaign!);
    body.ad_groups = body.ad_groups?.map(adGroup => {
      const responsiveSearchAd = JSON.parse(JSON.stringify(adGroup.responsive_search_ad)) || {} as unknown as typeof adGroup.responsive_search_ad ;
      const headlines: string[] = (responsiveSearchAd as any)?.$headlines || [];
      const descriptions: string[] = (responsiveSearchAd as any)?.$descriptions || [];
      new Array(15).fill('').forEach((item, index) => {
        _.set(responsiveSearchAd!, `headline_${index + 1}`, headlines[index] || null);
      });
      new Array(4).fill('').forEach((item, index) => {
        _.set(responsiveSearchAd!, index === 0 ? 'description' : `description_${index + 1}`, descriptions[index] || null);
      });
      return {
        ...adGroup,
        responsive_search_ad: _.omit(responsiveSearchAd, ['$headlines', '$descriptions']) as any,
      };
    });
    // put返回的ad_group顺序和重新get回来的顺序不一样
    await request.put<AutomationCampaignType>(`/merchants/${this.merchantId}/automation_campaigns/${this.campaignId}`, { google_campaign: body });
  }
}
