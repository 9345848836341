import { ref } from 'vue';

export function useLoading(func: Function, onHook?: (hook: () => any) => void) {
  const loading = ref(false);
  async function execFuc() {
    try {
      loading.value = true;
      await func();
    } finally {
      loading.value = false;
    }
  }
  if (onHook) {
    onHook(execFuc);
  } else {
    execFuc();
  }
  return loading;
}
